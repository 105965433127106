import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import Table from "react-bootstrap/Table"
import useTranslations from "components/use-translations"

export default function Referanslar() {
  const {
    genel: { referanslar },
    kutuphaneReferanslar: {
      seoTitle,
      seoDescription,
      referansDetay,
      kurum,
      ilIlce,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/kutuphane-programi/referanslar"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.Kutuphane}
          activeMenuItem={MenuItemType.Referanslar}
        />
        <Row>
          <Col>
            <h1>{referanslar}</h1>
            <small>
              {referansDetay.replace("#tarih#", new Date().toLocaleString())}
            </small>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{kurum}</th>
                  <th>{ilIlce}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TİMPAS</td>
                  <td>ADİYAMAN – KAHTA</td>
                </tr>
                <tr>
                  <td>MURAT KİTAPÇIM</td>
                  <td>AFYONKARAHİSAR</td>
                </tr>
                <tr>
                  <td>TASOVA YÜKSEL AKİN MYO</td>
                  <td>AMASYA – TAŞOVA</td>
                </tr>
                <tr>
                  <td>ALİ ŞİR NEVAİ ORTAOKULU</td>
                  <td>ANKARA – MAMAK</td>
                </tr>
                <tr>
                  <td>ABDULBAKİ ÜNLÜ IMAM HATİP ORTAOKULU</td>
                  <td>ANKARA – ŞEREFLİKOÇHİSAR</td>
                </tr>
                <tr>
                  <td>AKSEKİ EMİNE AHMET BÜKÜSOGLU ORTAOKULU</td>
                  <td>ANTALYA – AKSEKİ</td>
                </tr>
                <tr>
                  <td>MCDD</td>
                  <td>ANTALYA – MURATPAŞA</td>
                </tr>
                <tr>
                  <td>SOĞUCAK ORTAOKULU</td>
                  <td>AYDIN – KUŞADASI</td>
                </tr>
                <tr>
                  <td>ŞƏXSİ</td>
                  <td>AZERBAYCAN – BAKÜ</td>
                </tr>
                <tr>
                  <td>ATATÜRK ANADOLU LİSESİ</td>
                  <td>BATMAN </td>
                </tr>
                <tr>
                  <td>BAYBURT AİHL</td>
                  <td>BAYBURT</td>
                </tr>
                <tr>
                  <td>SARIBEY SERKAN ARGIN ORTAOKULU</td>
                  <td>BURSA – KARACABEY</td>
                </tr>
                <tr>
                  <td>YDYO</td>
                  <td>BURSA – OSMANGAZİ</td>
                </tr>
                <tr>
                  <td>BURAK ELEKTRİK</td>
                  <td>ÇANKIRI</td>
                </tr>
                <tr>
                  <td>HACI İRFAN MERSİN CUMHURİYET ORTAOKULU</td>
                  <td>DENİZLİ – SARAYKÖY</td>
                </tr>
                <tr>
                  <td>ATATÜRK ORTAOKULU</td>
                  <td>DİYARBAKIR – ÇÜNGÜŞ</td>
                </tr>
                <tr>
                  <td>MELİKAHMET KÜTÜPHANESİ</td>
                  <td>DİYARBAKIR – SUR</td>
                </tr>
                <tr>
                  <td>DEGİRMENKAYA ORTAOKULU</td>
                  <td>ERZURUM</td>
                </tr>
                <tr>
                  <td>KÖYCEĞİZ ORTAOKULU</td>
                  <td>ERZURUM – KARAYAZI</td>
                </tr>
                <tr>
                  <td>ISPIR ÜLKÜ OCAKLARI</td>
                  <td>ERZURUM İSPİR</td>
                </tr>
                <tr>
                  <td>YDYO</td>
                  <td>ESKİŞEHİR</td>
                </tr>
                <tr>
                  <td>ATATÜRK LİSESİ</td>
                  <td>ESKİŞEHİR</td>
                </tr>
                <tr>
                  <td>ÖZEL İDARE ANADOLU LİSESİ</td>
                  <td>GAZİANTEP – ŞAHİNBEY</td>
                </tr>
                <tr>
                  <td>FTML</td>
                  <td>GİRESUN – MERKEZ</td>
                </tr>
                <tr>
                  <td>
                    YEDİTEPE ÜNİVERSİTESİ HUKUK FAKÜLTESİ CEZA HUKUKU KÜRSÜSÜ
                  </td>
                  <td>İSTANBUL</td>
                </tr>
                <tr>
                  <td>İMZAOĞLU AİLE KÜTÜPHANESİ</td>
                  <td>İSTANBUL</td>
                </tr>
                <tr>
                  <td>CEM VAKFI</td>
                  <td>İSTANBUL</td>
                </tr>
                <tr>
                  <td>ANKİ</td>
                  <td>İSTANBUL</td>
                </tr>
                <tr>
                  <td>
                    YEDİTEPE ÜNİVERSİTESİ CEZA HUKUKU ARAŞTIRMA KÜTÜPHANESİ
                  </td>
                  <td>İSTANBUL – ATAŞEHİR</td>
                </tr>
                <tr>
                  <td>SEBAHATTİN ZAİM İMAM-HATİP ORTAOKULU</td>
                  <td>İSTANBUL – BAĞCILAR</td>
                </tr>
                <tr>
                  <td>GÜNER AKIN ANADOLU İHL</td>
                  <td>İSTANBUL – BEYOĞLU</td>
                </tr>
                <tr>
                  <td>KAĞITHANE KIZ ANADOLU İMAM HATİP LİSESİ</td>
                  <td>İSTANBUL – KAĞITHANE</td>
                </tr>
                <tr>
                  <td>ATAKANS ROOM</td>
                  <td>İSTANBUL – KARTAL</td>
                </tr>
                <tr>
                  <td>KEMAL TÜRKLER İLKOKULU</td>
                  <td>İSTANBUL – SANCAKTEPE</td>
                </tr>
                <tr>
                  <td>ENGİN</td>
                  <td>İSTANBUL – ŞİŞLİ</td>
                </tr>
                <tr>
                  <td>SİLAHTARAGA ILKOKULU</td>
                  <td>İSTANBUL EYÜP</td>
                </tr>
                <tr>
                  <td>İOTT KÜTÜPHANE</td>
                  <td>İZMİR</td>
                </tr>
                <tr>
                  <td>
                    İZMİR TOKİ KARŞIYAKA BELEDİYESİ MESLEKİ VE TEKNİK ANADOLU
                    LİSESİ
                  </td>
                  <td>İZMİR-KARŞIYAKA</td>
                </tr>
                <tr>
                  <td>PİRİ REİS İLKOKULU</td>
                  <td>KARAMAN – KAZIMKARABEKİR</td>
                </tr>
                <tr>
                  <td>CİDE YATILI BÖLGE ORTAOKULU</td>
                  <td>KASTAMONU – CİDE</td>
                </tr>
                <tr>
                  <td>KAYSERİ FİNAL OKULLARI</td>
                  <td>KAYSERİ – TALAS</td>
                </tr>
                <tr>
                  <td>NEDİM ÖKMEN ANADOLU LİSESİ</td>
                  <td>KİLİS</td>
                </tr>
                <tr>
                  <td>GÖLCÜK İMAM HATİP LİSESİ</td>
                  <td>KOCAELİ – GÖLCÜK</td>
                </tr>
                <tr>
                  <td>KARAPINAR CUMHURİYET ORTAOKULU</td>
                  <td>KONYA – KARAPINAR</td>
                </tr>
                <tr>
                  <td>HİSARBEY İLKOKULU</td>
                  <td>KÜTAHYA – SİMAV</td>
                </tr>
                <tr>
                  <td>HANE-İ KÜTÜP</td>
                  <td>MALATYA</td>
                </tr>
                <tr>
                  <td>ŞEHİT ABDULLAH TAYYİP OLÇOK OO</td>
                  <td>MANİSA – TURGUTLU</td>
                </tr>
                <tr>
                  <td>TEK KOLEJİ</td>
                  <td>MENTEŞE – MUĞLA</td>
                </tr>
                <tr>
                  <td>AKDENİZ SİYASET VE TOPLUM ARAŞTIRMALARI MERKEZİ</td>
                  <td>MERSİN</td>
                </tr>
                <tr>
                  <td>KAZANLI İLKOKULU</td>
                  <td>MERSİN – AKDENİZ</td>
                </tr>
                <tr>
                  <td>MENTEŞ</td>
                  <td>MERSİN – YENİŞEHİR</td>
                </tr>
                <tr>
                  <td>ORTACA ORTAOKULU</td>
                  <td>MUĞLA – ORTACA</td>
                </tr>
                <tr>
                  <td>GÖLKÖY ANADOLU İMAM HATİP LİSESİ</td>
                  <td>ORDU – GÖLKÖY</td>
                </tr>
                <tr>
                  <td>İL GENEL MECLİSİ ORTAOKULU</td>
                  <td>OSMANİYE</td>
                </tr>
                <tr>
                  <td>CEYLAN İÖO</td>
                  <td>SAKARYA</td>
                </tr>
                <tr>
                  <td>VEYSEL KARANİ Ç.P.A.L</td>
                  <td>SİİRT – BAYKAN</td>
                </tr>
                <tr>
                  <td>GAZİOSMANPAŞA ORTAOKULU</td>
                  <td>TOKAT – TURHAL</td>
                </tr>
                <tr>
                  <td>AHMET YURTSEVER MESLEKİ VE TEKNİK ANADOLU LİSESİ</td>
                  <td>VAN</td>
                </tr>
                <tr>
                  <td>FELXX1453 LİSESİ</td>
                  <td>YOZGAT </td>
                </tr>
                <tr>
                  <td>MASAL</td>
                  <td>ZONGULDAK</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.Kutuphane}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.SikSorulanSorular,
                MenuItemType.EkranGoruntuleri,
                MenuItemType.KullanimKilavuzu,
                MenuItemType.Referanslar,
              ]}
              activeMenuItem={MenuItemType.Referanslar}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
